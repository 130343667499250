import request from '@/utils/request'

export default {
  tree(params) {
    return request({
      url: '/v1/categoryTree',
      method: 'get',
      params
    })
  },
  eqList(params) {
    return request({
      url: '/v1/equipmentList',
      method: 'get',
      params
    })
  },
  getOne(id) {
    return request({
      url: `/v1/equipment/${id}`,
      method: 'get'
    })
  },
  create(data) {
    return request({
      url: '/v1/equipmentAdd',
      method: 'post',
      data
    })
  },
  update(data) {
    return request({
      url: '/v1/equipmentUpdate',
      method: 'post',
      data
    })
  },
  delete(id) {
    return request({
      url: `/v1/equipmentDel/${id}`,
      method: 'get'
    })
  },
  eqPlanAdminSave(data) {
    return request({
      url: '/v1/eqAdd',
      method: 'post',
      data
    })
  },
  renew(id) {
    return request({
      url: `/v1/eqRenew/${id}`,
      method: 'get'
    })
  },
  categoryTree() {
    return request({
      url: '/v1/categoryTree',
      method: 'get'
    })
  },
  positionTree() {
    return request({
      url: '/v1/positionTree',
      method: 'get'
    })
  },
  excel(data) {
    return request({
      url: '/v1/eqExcel',
      method: 'post',
      data
    })
  },
  append(data) {
    return request({
      url: '/v1/eqAdminAppend',
      method: 'post',
      data
    })
  },
  remove(data) {
    return request({
      url: '/v1/eqAdminRemove',
      method: 'post',
      data
    })
  }
}
